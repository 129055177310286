import { getQuoteOrBookingErrorMessage } from '@/components/property/utils'
import { isoDateFormat } from '@/constants'
import ErrorCodes from '@/constants/error-codes'
import { useBeacon } from '@/lib/beacon'
import { getApi } from '@/lib/client/api'
import { expandDates, selectDateRange } from '@/lib/utils'
import {
  addDays,
  differenceInCalendarDays,
  format,
  parseISO,
  subDays
} from 'date-fns'
import dayjs from 'dayjs'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

const api = getApi()

/**
 *
 * @param {Date | undefined}startDate
 * @param {Date | undefined} endDate
 * @return {{from: Date | undefined, to: Date | null}}
 */
const getStartDates = (startDate, endDate) => {
  if (!startDate || !endDate)
    return {
      from: undefined,
      to: null
    }
  return {
    from: startDate,
    to: endDate
  }
}
/**
 *
 * @param {dayjs.Dayjs | Date | undefined} startDate
 * @param {dayjs.Dayjs | Date | undefined} endDate
 * @param {dayjs.Dayjs[] | undefined} excludedDates
 * @return {{from: Date| undefined, to: Date | undefined}}
 */
export const findLastAvailableDate = (startDate, endDate, disabledDates) => {
  if (!startDate || !endDate)
    return {
      from: startDate,
      to: endDate
    }
  startDate = dayjs(startDate)
  endDate = dayjs(endDate)
  while (
    expandDates(dayjs(startDate), dayjs(endDate))
      .map((x) => disabledDates.includes(x.format(isoDateFormat)))
      .some(Boolean)
  ) {
    endDate = endDate.subtract(1, 'day')
  }
  return {
    from: startDate.toDate(),
    to: endDate.toDate()
  }
}

/**
 *
 * @param {dayjs.Dayjs[]|undefined} excludedDates
 * @param {number} minStay
 * @return {{from: Date, to: Date}}
 */
export const findFirstCheckinDate = (excludedDates, minStay = 1) => {
  const disabledDates = (excludedDates ?? []).map((x) =>
    x.format(isoDateFormat)
  )
  let startDate = dayjs().hour(0).minute(0).second(0).millisecond(0)
  let endDate = startDate.add(minStay, 'day')
  while (
    expandDates(startDate, endDate)
      .map((x) => disabledDates.includes(x.format(isoDateFormat)))
      .some(Boolean)
  ) {
    startDate = startDate.add(1, 'day')
    endDate = startDate.add(minStay, 'day')
  }

  return {
    from: startDate.toDate(),
    to: endDate.toDate()
  }
}

const QuoteContext = createContext(undefined)

const QuoteContextProvider = ({
  children,
  rawUnavailableDates,
  propertyId,
  engineId,
  settings,
  startDate = null,
  endDate = null,
  longTermSettings
}) => {
  const beacon = useBeacon()
  const unavailableDates = rawUnavailableDates.map((ed) => dayjs(ed))
  const { query } = useRouter()
  const [customQuoteChanged, setCustomQuoteChanged] = useState(false)

  const { t, i18n } = useTranslation('error-messages')
  const newDates = getStartDates(startDate, endDate)
  const [dates, setDates] = useState({
    ...newDates
  })
  const [data, setData] = useState({
    quote: {},
    isLoading: false,
    error: null
  })

  const isCustomQuote = useMemo(() => {
    return !!query?.quote_id
  }, [query])

  const resetDates = () => {
    setQuote({})
    setDates({
      ...dates,
      from: undefined,
      to: null
    })
  }

  const addQuoteExtraParams = (quoteRequest, checkinDate, checkoutDate) => {
    if (isCustomQuote) {
      const quoteChanged = checkIfCustomQuoteChanged({
        from: checkinDate,
        to: checkoutDate
      })

      if (query?.quote_id && !quoteChanged)
        quoteRequest['quote_id'] = query?.quote_id
    }
    if (query?.adults) quoteRequest['adults'] = query?.adults
    if (query?.children) quoteRequest['children'] = query?.children
    if (query?.pets) quoteRequest['pets'] = query?.pets

    return quoteRequest
  }
  const isInvalidCheckDate = [dates.from, dates.to]
    .map((dt) => {
      if (!dt) return true
      const formattedDate = dayjs(dt).format(isoDateFormat)
      return unavailableDates.includes(formattedDate)
    })
    .some(Boolean)

  const checkIfCustomQuoteChanged = (range) => {
    if (
      !!query?.quote_id &&
      range.from &&
      range.to &&
      query.checkin &&
      query.checkout
    ) {
      if (
        format(range.from, 'yyyy-MM-dd') !== query.checkin ||
        format(range.to, 'yyyy-MM-dd') !== query.checkout
      ) {
        return true
      }
    }
    return false
  }

  const revertCustomQuoteDates = () => {
    const checkin = parseISO(query.checkin)
    const checkout = parseISO(query.checkout)
    setDates({
      from: checkin,
      to: checkout
    })
    setCustomQuoteChanged(false)
    fetchQuote(checkin, checkout)
  }

  const onDateChange = (range, selectedDay) => {
    range = selectDateRange(dates, selectedDay)
    if (isCustomQuote) {
      setCustomQuoteChanged(checkIfCustomQuoteChanged(range))
    }
    const { disabledDays } = checkInOutDays
    const newDates = findLastAvailableDate(
      range?.from,
      range?.to,
      disabledDays
    )
    setDates(newDates)
    return newDates
  }
  const setIsLoadingQuote = () => setData({ ...data, isLoading: true })
  const setQuote = (newQuote) =>
    setData({
      ...data,
      quote: newQuote,
      isLoading: false,
      error: null
    })
  const setErrorMessage = (message) =>
    setData({ ...data, quote: {}, isLoading: false, error: message })

  const fetchQuote = async (checkin = null, checkout = null) => {
    const checkinDate = checkin ?? dates.from
    const checkoutDate = checkout ?? dates.to

    const {
      prevent_long_term_stays,
      prevent_long_term_stays_days,
      phone_number
    } = longTermSettings

    if (
      prevent_long_term_stays &&
      differenceInCalendarDays(checkoutDate, checkinDate) >
      prevent_long_term_stays_days
    ) {
      setErrorMessage(
        t('quote-error.prevent_long_term_stays', {
          days: prevent_long_term_stays_days,
          tel: phone_number
        })
      )

      return false
    }

    if (dayjs(checkinDate).format(isoDateFormat) < dayjs(Date()).format(isoDateFormat)) {
      setErrorMessage(t('quote-error.check-in_day_in_past'))

      return false
    }

    setIsLoadingQuote()

    let quoteRequest = {
      pid: propertyId,
      checkin: dayjs(checkinDate).format(isoDateFormat),
      checkout: dayjs(checkoutDate).format(isoDateFormat),
      adults: 1
    }

    quoteRequest = addQuoteExtraParams(quoteRequest, checkinDate, checkoutDate)

    try {
      return api.getQuote(engineId, quoteRequest).then((data) => {
        if (data.bookable === false) {
          let transKey = 'error-messages:booking-error.' + data.reason
          let fallbackTransKey = 'error-messages:booking-error.unknown'
          const message = getQuoteOrBookingErrorMessage(
            t,
            i18n,
            transKey,
            fallbackTransKey,
            data,
            checkinDate,
            checkoutDate,
            settings
          )

          beacon.sendPageQuote({ params: quoteRequest }, data)
          setErrorMessage(message)
          return false
        }

        if (data.code === ErrorCodes.LONG_TERM_STAYS_NOT_ALLOWED) {
          beacon.sendPageQuote({ params: quoteRequest }, data)
          setErrorMessage(data.detail)
          return false
        }
        beacon.sendPageQuote(data)
        setQuote({
          ...data.params,
          ...data.quote,
          saved_quote: data.saved_quote || null
        })
        return true
      })
    } catch (e) {
      beacon.sendPageQuote({ ...quoteRequest, error: e })
      setErrorMessage(t('quote-error.fetch-quote-system-error'))
      return false
    } finally {
      setIsLoadingQuote(false)
    }
  }

  const checkInOutDays = useMemo(() => {
    const checkInDays = []
    const checkOutDays = []
    const disabledDays = []

    rawUnavailableDates.forEach((unavailableDay, index) => {
      const currentDay = parseISO(unavailableDay)
      const nextDay = addDays(currentDay, 1)
      const prevDay = subDays(currentDay, 1)

      //if nextday is available it's a checkout
      if (rawUnavailableDates[index + 1] !== format(nextDay, 'yyyy-MM-dd')) {
        checkOutDays.push(nextDay)
      }

      //if prevDay is available then it's a checkin
      if (rawUnavailableDates[index - 1] !== format(prevDay, 'yyyy-MM-dd')) {
        checkInDays.push(currentDay)
      } else {
        disabledDays.push(currentDay)
      }
    })

    return {
      checkInDays,
      checkOutDays,
      disabledDays
    }
  }, [rawUnavailableDates])

  const exportedValues = {
    data,
    dates,
    onDateChange,
    revertCustomQuoteDates,
    fetchQuote,
    resetDates,
    isCustomQuote,
    customQuoteChanged,
    isInvalidCheckDate,
    unavailableDates,
    ...checkInOutDays,
    longTermSettings
  }

  useEffect(() => {
    if (!!startDate && !!endDate) {
      fetchQuote(startDate, endDate)
    }
    // !FIXME: make fetchQuote a stable reference and add to deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  return (
    <QuoteContext.Provider value={exportedValues}>
      {children}
    </QuoteContext.Provider>
  )
}
export default QuoteContextProvider

export const useQuoteContext = () => {
  const context = useContext(QuoteContext)
  if (context === undefined)
    throw new Error('Context must be defined within a provider')
  return context
}
