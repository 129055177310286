import { Property } from '@/components/pages/Property'
import SEO_PAGES from '@/constants/seo-pages'
import { withContext } from '@/lib/server/context'
import { hasCookie, setCookie } from 'cookies-next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { v4 as uuidv4 } from 'uuid'

export const getServerSideProps = withContext(
  async ({ locale, req, res, query }, { api, engineId }) => {
    const hasUuidCookie = hasCookie('uuid', { req, res })
    const uuid = hasUuidCookie ? req.cookies.uuid : uuidv4()

    if (!hasUuidCookie) {
      setCookie('uuid', uuid, {
        res,
        req,
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) // one year
      })
    }

    const [property, page, paymentConfiguration] = await Promise.all([
      api.getPropertyDetails(engineId, query.slug),
      api.getPage(engineId, SEO_PAGES['properties/<slug>'], {
        slug: query.slug,
        tracker_id: uuid
      }),
      api.paymentConfiguration(engineId, query.slug)
    ])
    const { settings } = page

    const { is_quote_supported: isQuoteSupported } = page
    const isCustomerInterested = await api.getEngineInterest(
      engineId,
      isQuoteSupported
    )

    return {
      props: {
        ...(await serverSideTranslations(locale, [
          'property',
          'common',
          'custom-quote',
          'reviews',
          'error-messages'
        ])),
        property,
        settings: { ...settings, isCustomerInterested },
        page,
        engineId,
        paymentConfiguration
      }
    }
  }
)

export default Property
