import dayjs from 'lib/dayjs'

const DOW = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
}

const operationLookup = {
  append: (description, additionalText) => description + additionalText,
  overwrite: (description, additionalText) => additionalText,
  prepend: (description, additionalText) => additionalText + description
}
/**
 *
 * @param {DetailedProperty} property
 * @return {string}
 */
export const getPropertyDescription = (property) => {
  const description = property.descrip ?? ''
  const propertyOverride = property.property_info.desc_override ?? ''
  const additionalText = property.property_info.prop_desc ?? ''
  if (propertyOverride in operationLookup && additionalText !== '')
    return operationLookup[propertyOverride](description, additionalText)
  return description
}

export const getQuoteOrBookingErrorMessage = (
  t,
  i18n,
  transKey,
  fallbackTransKey,
  quote,
  checkin,
  checkout,
  settings
) => {
  const transKeyExist = i18n.exists(transKey)
  return t(!transKeyExist ? fallbackTransKey : transKey, {
    checkin_day_of_week: DOW[dayjs(checkin).weekday()],
    checkout_day_of_week: DOW[dayjs(checkout).weekday()],
    min_stay: quote.details?.min_stay,
    max_stay: quote.details?.max_stay,
    phone_number: settings.defaultSeo.call2,
    company_name: settings.companyName
  })
}
