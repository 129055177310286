import FormInput from '@/components/core/form/FormInput'
import { StarRating } from '@/components/core/StarRating'
import DateInputWithPopoverCalendar from '@/components/property/DateInputWithPopoverCalendar'
import { validateEmailRegExp } from '@/constants'
import { usePropertyContext } from '@/contexts/property'
import { useSignalContext } from '@/contexts/signal'
import { getApi } from '@/lib/client/api'
import { formatDate, getDateFormatFromLocale } from '@/lib/localeUtils'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Skeleton,
  Spacer,
  Stack,
  Text,
  Textarea,
  useStyleConfig,
  useToast,
  VStack
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMountedState } from 'react-use'
import useSWR from 'swr'
import PropertyReview from './PropertyReview'

const api = getApi()

const ReviewThisPropertyForm = ({ setIsReviewing, setIsAbleToReview }) => {
  const isMounted = useMountedState()
  const { t } = useTranslation('property')
  const toast = useToast()
  const [starValue, setStarValue] = useState()
  const [starError, setStarError] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const {
    engineId,
    property: { id: propertyId }
  } = usePropertyContext()
  const { locale } = useSignalContext()

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      title: '',
      review: '',
      dateInputWithPopoverCalendar: ''
    }
  })

  const {
    formState: { errors },
    handleSubmit,
    register
  } = formMethods

  const sendRequest = (data) => {
    if (!starValue) {
      setStarError(t('selecting-a-rating-required'))
      return false
    }

    const formattedCheckInDate = formatDate(
      data.dateInputWithPopoverCalendar,
      locale,
      'yyyy-MM-dd',
      {
        parseFormat: getDateFormatFromLocale(locale)
      }
    )

    setIsButtonDisabled(true)
    api
      .postPropertyReview(engineId, propertyId, {
        rating: starValue,
        check_in: formattedCheckInDate,
        guest_email: data.email,
        review: data.review,
        display_name: data.name,
        title: data.title
      })
      .then(
        () => {
          if (isMounted()) {
            //success
            formMethods.reset()
            toast({
              title: t('thank-you'),
              description: t('review-submission-success'),
              status: 'success',
              duration: 8000,
              isClosable: true
            })
            setIsAbleToReview(false)
            setIsReviewing(false)
          }
        },
        () => {
          if (isMounted()) {
            toast({
              title: 'Error',
              description: t('review-submission-failure'),
              status: 'error',
              duration: 8000,
              isClosable: true
            })
            setIsButtonDisabled(false)
          }
        }
      )
  }

  return (
    <Box>
      <Button
        color="currentcolor"
        my={5}
        onClick={() => setIsReviewing(false)}
        variant="link"
      >
        <ChevronLeftIcon w="2rem" h="2rem" />
        Back
      </Button>
      <HStack mt={6} spacing={4}>
        <StarRating
          isInteractive
          newValue={starValue}
          setNewValue={(val) => {
            setStarError('')
            setStarValue(val)
          }}
          size={['1.7rem', null, '1.8rem', '2rem']}
        />
        <Text ml={4}>{t('select-your-rating')}</Text>
      </HStack>
      {starError && <Text color="red.500">{starError}</Text>}
      <FormProvider {...formMethods}>
        <form
          className="property-review-form"
          onSubmit={handleSubmit(sendRequest)}
        >
          <Flex
            color="gray.600"
            fontSize={'sm'}
            justifyContent="flex-end"
            mt={4}
            pt={5}
            whiteSpace="nowrap"
          >
            * {t('required')}
          </Flex>
          <VStack spacing={5} pb={5}>
            <FormInput
              className="ph-no-capture"
              name="name"
              placeholder="Name *"
              required
            />
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing={5}
              w="100%"
            >
              <FormInput
                className="ph-no-capture"
                name="title"
                placeholder={`${t('title-of-review')} *`}
                required
              />
              <DateInputWithPopoverCalendar
                placeholderText={`${t('check-in-date')} *`}
              />
            </Stack>
            <FormInput
              className="ph-no-capture"
              name="email"
              placeholder={`${t('email')} *`}
              required
              validate
              validationRegExp={validateEmailRegExp}
            />
            <FormControl isInvalid={!!errors?.review?.message}>
              <Textarea
                className="ph-no-capture"
                id="review"
                name="review"
                placeholder={`${t('review')} *`}
                aria-invalid={errors.review ? 'true' : 'false'}
                {...{
                  ...register('review', {
                    required: {
                      value: true,
                      message: t('this-field-required')
                    }
                  })
                }}
              />
              <FormErrorMessage>{errors?.review?.message}</FormErrorMessage>
            </FormControl>
            <Button
              borderRadius="md"
              className="btn-primary"
              colorScheme="wpHeaderScheme"
              disabled={isButtonDisabled}
              id="send-property-review"
              type="submit"
              w="100%"
            >
              {t('post-review')}
            </Button>
          </VStack>
        </form>
      </FormProvider>
    </Box>
  )
}

const ReviewThisProperty = ({ isReviewing, setIsReviewing }) => {
  const [isAbleToReview, setIsAbleToReview] = useState(true)
  const { t } = useTranslation('property')

  return (
    <>
      {!isReviewing && isAbleToReview && (
        <Button
          bg="wpMain.bg"
          borderColor="wpMain.primary"
          color="wpMain.primary"
          onClick={() => setIsReviewing(true)}
          px={[6, null, 9]}
          variant="outline"
        >
          {t('review-this-property')}
        </Button>
      )}
      {isReviewing && (
        <ReviewThisPropertyForm
          setIsReviewing={setIsReviewing}
          setIsAbleToReview={setIsAbleToReview}
        />
      )}
    </>
  )
}

const InternalPropertyReviews = ({ data, page, setPage }) => {
  const { t } = useTranslation('property')
  const isLoading = !data
  const reviews = data?.results
  const { isWriteReview } = usePropertyContext()
  const [isReviewing, setIsReviewing] = useState(isWriteReview)
  if (!reviews || reviews.length === 0) {
    return (
      <Skeleton isLoaded={!isLoading}>
        <ReviewThisProperty
          isReviewing={isReviewing}
          setIsReviewing={setIsReviewing}
        />
        {!isReviewing && (
          <Center height={300}>
            <Text>{t('no-reviews')}</Text>
          </Center>
        )}
      </Skeleton>
    )
  }

  return (
    <>
      <ReviewThisProperty
        isReviewing={isReviewing}
        setIsReviewing={setIsReviewing}
      />
      {!isReviewing && (
        <>
          {reviews.map((r, i) => (
            <PropertyReview review={r} key={i} isLoading={!data} />
          ))}
          <Flex>
            {data?.previous && (
              <Text
                as="u"
                _hover={{ color: 'gray.500' }}
                color="wpMain.primary"
                onClick={() => {
                  if (page - 1 > 0) setPage(page - 1)
                }}
              >
                {t('previous-number', { number: page - 1 })}
              </Text>
            )}
            <Spacer />
            {data?.next && (
              <Text
                as="u"
                _hover={{ color: 'gray.500' }}
                color="wpMain.primary"
                onClick={() => {
                  setPage(page + 1)
                }}
              >
                {t('next-number', { number: page + 1 })}
              </Text>
            )}
          </Flex>
        </>
      )}
    </>
  )
}
export const PropertyReviews = ({ property, isOpen = true }) => {
  const [page, setPage] = useState(1)
  const { engineId } = usePropertyContext()

  const getReviews = () => api.getPropertyReviews(engineId, property.id, page)
  const { data } = useSWR(
    `${engineId}/${property.id}/reviews-${page}`,
    getReviews
  )
  const style = useStyleConfig('Box', { variant: 'mobileProperty' })
  if (!isOpen) return null
  return (
    <Box sx={style} className="property-reviews">
      <InternalPropertyReviews data={data} page={page} setPage={setPage} />
    </Box>
  )
}
