import { useSignalContext } from '@/contexts/signal'
import { currencyFormatter } from '@/lib/currency'
import { localeFromISODate } from '@/lib/localeUtils'
import { Flex, Heading, Skeleton, Stack, Text } from '@chakra-ui/react'
import { getSuffixForN } from 'lib/utils'

const PaymentSchedule = ({
  title,
  isLoading,
  payments,
  size = 'md',
  currency,
  ...props
}) => {
  const { locale } = useSignalContext()
  const formatCurrency = currencyFormatter(currency, locale)

  return (
    <Stack spacing={4} fontSize={size} {...props}>
      <Heading size={size} as="p">
        {title}
      </Heading>
      {payments?.map((payment, i) => {
        const readableIndex = i + 1
        const dueDate = localeFromISODate(payment.due_date, locale)
        const ordinal = `${readableIndex}${getSuffixForN(
          readableIndex
        )} Payment`
        const paymentString = `${ordinal} (${dueDate})`
        return (
          <Skeleton isLoaded={!isLoading} key={i}>
            <Flex key={i} justify="space-between">
              <Text as="span">{paymentString}</Text>
              <Text as="span">{formatCurrency(payment.amount)}</Text>
            </Flex>
          </Skeleton>
        )
      })}
    </Stack>
  )
}

export default PaymentSchedule
