import { PageSubtitle, PageTitle } from '@/components/core/page'
import { PropertyDetailHeader } from '@/components/core/PropertyDetailHeader'
import { usePropertyContext } from '@/contexts/property'
import { Box, useBreakpointValue } from '@chakra-ui/react'
import React from 'react'

/**
 *
 * @param {DetailedProperty} property
 * @param {SignalSettings} settings
 * @returns {JSX.Element}
 * @constructor
 */
export const PageSplash = ({ property, settings }) => {
  const { seo } = usePropertyContext()
  const displayH2 = settings.displayH2 && Boolean(seo.page_h2)
  const direction = useBreakpointValue({ base: 'column', md: 'row' })

  return (
    <Box flex="full" className="property-page__splash" pb={5}>
      <PageTitle mb={3}>{seo.page_h1}</PageTitle>
      {displayH2 && <PageSubtitle mb={3}>{seo.page_h2}</PageSubtitle>}
      <PropertyDetailHeader
        spacing="3"
        property={property}
        direction={direction}
      />
    </Box>
  )
}
