import { createContext, useContext } from 'react'

const PropertyContext = createContext(undefined)

const PropertyContextProvider = ({
  children,
  property,
  seo,
  latLng,
  engineId,
  tabs,
  queryInTabs,
  isWriteReview
}) => {
  const exportedValues = {
    property,
    seo,
    latLng,
    engineId,
    slug: property.slug,
    tabs,
    queryInTabs,
    isWriteReview
  }
  return (
    <PropertyContext.Provider value={exportedValues}>
      {children}
    </PropertyContext.Provider>
  )
}
export default PropertyContextProvider

export const usePropertyContext = () => {
  const context = useContext(PropertyContext)
  if (context === undefined)
    throw new Error('Context must be defined within a provider')
  return context
}
