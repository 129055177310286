import { Box, useStyleConfig } from '@chakra-ui/react'
import { PageDescription } from '../core/page'

export const PropertySpecials = ({ property }) => {
  const style = useStyleConfig('Box', { variant: 'mobileProperty' })
  const specials = property.specials ?? []
  return (
    <Box sx={style} py={5} className="property-specials">
      {specials.map((special, idx) => (
        <PageDescription key={idx}>{special.description}</PageDescription>
      ))}
    </Box>
  )
}
