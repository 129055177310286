import { usePageContext } from '@/contexts/page'
import alarmClock from '@/icons/alarm-clock.svg'
import {
  Alert,
  AlertDescription,
  CloseButton,
  Icon,
  useDisclosure
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

export const UrgencyPrompt = ({ ...props }) => {
  const { propertyTracker } = usePageContext()
  const { enabled, views, booked } = propertyTracker || {}
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })
  const { t } = useTranslation('property')
  const message = () => {
    if (booked > 1) {
      return t('booked-times', {
        booked
      })
    }
    if (views > 10) {
      return t('viewed-times', {
        views
      })
    }
    return t('act-fast')
  }

  return isVisible && enabled ? (
    <Alert
      status="info"
      {...props}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bg="unset"
    >
      <Icon as={alarmClock} boxSize={5} color="gray.500" />
      <AlertDescription px={4}>{message()}</AlertDescription>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={onClose}
        color="gray.300"
        _hover={{ color: 'gray.500' }}
      />
    </Alert>
  ) : null
}
