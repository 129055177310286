import ArrowRight from '@/icons/arrow-right.svg'
import ArrowUp from '@/icons/arrow-up.svg'
import { Box, Button, Heading, Icon, useBreakpointValue } from '@chakra-ui/react'
import React, { useState } from 'react'

/**
 *
 * @param {string} description
 * @return {JSX.Element}
 * @constructor
 */
export const PropertyDescription = ({ description }) => {
  const characterCount = useBreakpointValue({ base: 500, md: 1500 })
  const descriptionLength = description.length
  const showButton = descriptionLength > characterCount

  const [showMore, setShowMore] = useState(descriptionLength < characterCount)
  if (!showMore && showButton) {
    description = description.substring(0, characterCount)
    description += '...'
  }

  return (
    <Box>
      <Heading as="p" size="md" mb={5}>
        Description
      </Heading>
      <Box
        overflow="auto"
        whiteSpace="normal"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {showButton && (
        <Button
          id="show-description"
          size="sm"
          my={4}
          variant="unstyled"
          _hover={{
            textDecoration: 'underline'
          }}
          rightIcon={
            showMore ? (
              <Icon as={ArrowUp} boxSize={2} fill="gray.800" />
            ) : (
              <Icon as={ArrowRight} boxSize={2} fill="gray.800" />
            )
          }
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </Box>
  )
}
