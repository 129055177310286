import useSignalPath from '@/hooks/useSignalPath'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Button, HStack, Icon, Link, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

const CustomQuoteBackBtn = () => {
  const { t } = useTranslation('custom-quote')
  const signalPath = useSignalPath()
  return (
    <HStack justifyContent="flex-end" alignItems="center" fontSize="sm" mb={4}>
      <Link
        style={{ textDecoration: 'none' }}
        href={signalPath('/custom-quote', { query: {} })}
      >
        <Button variant="ghost">
          <Icon
            mr={-1}
            color={'wpMain.secondary'}
            boxSize={5}
            as={ChevronLeftIcon}
          />
          <Text ml="10px">{t('back-to-specials')}</Text>
        </Button>
      </Link>
    </HStack>
  )
}

export default CustomQuoteBackBtn
