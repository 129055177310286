import { OverallReview } from '@/components/property/OverallReview'
import { Box, Text } from '@chakra-ui/react'
import React from 'react'

export const SideBarHeader = ({
  property,
  isQuote,
  isMobile,
  setTabIndex = () => null,
  reviewsRef,
  reviewsIsOpen,
  reviewsToggle
}) => {
  return (
    <Box pt={5}>
      <OverallReview
        property={property}
        isMobile={isMobile}
        setTabIndex={setTabIndex}
        reviewsRef={reviewsRef}
        reviewsIsOpen={reviewsIsOpen}
        reviewsToggle={reviewsToggle}
      />
      <Box sx={{ marginBottom: 3 }} display={isQuote ? 'none' : 'block'}>
        <Text fontSize={['md', 'md']}>Please select dates.</Text>
      </Box>
    </Box>
  )
}
