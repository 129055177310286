import { loadAmenityIcon } from '@/components/property/utils/amenity'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Grid,
  Heading,
  HStack,
  Icon,
  Text,
  useBreakpointValue
} from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'

const ShowAllButton = ({
  showAll,
  setShowAll,
  amenitiesCount,
  maxNumberShown
}) => {
  if (amenitiesCount <= maxNumberShown) return null
  const icon = showAll ? <ChevronUpIcon /> : <ChevronDownIcon />
  const text = showAll ? 'Show Less' : `Show all ${amenitiesCount} amenities`

  return (
    <HStack spacing={2} onClick={() => setShowAll(!showAll)}>
      <Text as="u">{text}</Text>
      {icon}
    </HStack>
  )
}
/**
 *
 * @param {PropertyAmenities[]} amenities
 * @param {Boolean} showAll
 * @param {Number} maxNumberShown
 * @return {JSX.Element|null[]}
 */
const AmenityRows = ({ amenities, showAll, maxNumberShown }) => {
  const showAmenityIcons = useMemo(() => {
    return amenities.some((amenity) => amenity.icon && amenity.icon !== '')
  }, [amenities])

  return amenities.map((a, i) => {
    if (!showAll && i > maxNumberShown) return null
    const innerText = (
      <Text key={i} sx={{ width: '100%' }}>
        {a.amenity}
      </Text>
    )
    const amenityIcon = loadAmenityIcon(a.icon)
    if (!!amenityIcon && showAmenityIcons) {
      return (
        <HStack key={i} alignContent="center">
          <Icon w={6} h={6} as={amenityIcon} fill="#a8a8a8" />
          {innerText}
        </HStack>
      )
    }
    return innerText
  })
}

/**
 * @param {PropertyAmenities[]} amenities
 * @return {JSX.Element}
 * @constructor
 */
export const Amenities = ({ amenities }) => {
  const [showAll, setShowAll] = useState(false)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const templateColumns = useBreakpointValue({
    base: 'repeat(1, 1fr)',
    md: 'repeat(2, 1fr)',
    lg: 'repeat(3, 1fr)'
  })
  const maxNumberShown = isMobile ? 4 : 14

  return (
    <Box>
      <Heading as="p" size="md" mb={5} mt={3}>
        Amenities
      </Heading>

      <Grid templateColumns={templateColumns} gap={3}>
        <AmenityRows
          amenities={amenities}
          showAll={showAll}
          maxNumberShown={maxNumberShown}
        />
      </Grid>
      <ShowAllButton
        amenitiesCount={amenities.length}
        showAll={showAll}
        setShowAll={setShowAll}
        maxNumberShown={maxNumberShown}
      />
    </Box>
  )
}
