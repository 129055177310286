import FormInput from '@/components/core/form/FormInput'
import FormPhoneNumberInput from '@/components/reservations/create-reservation/FormSection/FormPhoneNumberInput'
import { validateEmailRegExp } from '@/constants'
import { usePropertyContext } from '@/contexts/property'
import { useQuoteContext } from '@/contexts/quote'
import { useSignalContext } from '@/contexts/signal'
import { getApi } from '@/lib/client/api'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Text,
  Textarea,
  useStyleConfig,
  useToast,
  VStack
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

const api = getApi()

/**
 *
 * @param {{from: null | Date, to: null | Date}} dates
 * @return {string}
 */
const formatDateString = (dates) => {
  let output = ''
  const hasFrom = !!dates.from
  const hasTo = !!dates.to
  if (hasFrom) output += dates.from.toISOString().substring(0, 10)
  if (hasFrom && hasTo) output += ' - '
  if (hasTo) output += dates.to.toISOString().substring(0, 10)
  return output !== '' ? output : null
}

export const PropertyRequest = () => {
  const [emailUpdates, setEmailUpdates] = useState(false)
  const toast = useToast()
  const { token, locale } = useSignalContext()
  const { property } = usePropertyContext()
  const { dates } = useQuoteContext()
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      requested_dates: '',
      email: '',
      phone: '',
      comments: ''
    }
  })

  const sendRequest = (data) => {
    api
      .postPropertyRequest(token, property.pid, {
        ...data,
        requested_dates:
          data.requested_dates === ''
            ? formatDateString(dates)
            : data.requested_dates,
        email_updates: emailUpdates
      })
      .then(
        () => {
          formMethods.reset()
          toast({
            title: 'Request Submitted',
            description: "We've received the property request.",
            status: 'success',
            duration: 8000,
            isClosable: true
          })
        },
        () =>
          toast({
            title: 'Error',
            description: 'Something went wrong we have been notified.',
            status: 'error',
            duration: 8000,
            isClosable: true
          })
      )
  }
  const boxStyle = useStyleConfig('Box', { variant: 'mobileProperty' })
  const inputStyle = useStyleConfig('Input')
  const dateString = formatDateString(dates)
  return (
    <Box sx={boxStyle}>
      <FormProvider {...formMethods}>
        <form
          className="property-request-form"
          onSubmit={formMethods.handleSubmit(sendRequest)}
        >
          <VStack spacing={5}>
            <FormInput
              name="name"
              placeholder="Name"
              required
              className="ph-no-capture"
            />

            <FormInput
              name="email"
              placeholder="Email"
              validationRegExp={validateEmailRegExp}
              required
              validate
              className="ph-no-capture"
            />

            <FormPhoneNumberInput
              name="phone"
              placeholder="Phone Number"
              className="phone ph-no-capture"
              locale={locale}
            />

            <FormInput
              name="requested_dates"
              required={false}
              placeholder={dateString ?? 'Requested dates'}
              className="ph-no-capture"
            />

            <Textarea
              id="comments"
              my={5}
              placeholder="Comments"
              {...{ ...formMethods.register('comments'), ...inputStyle }}
            />
            <Flex style={{ width: '100%' }}>
              <Checkbox
                pr={5}
                onChange={() => setEmailUpdates(!emailUpdates)}
              />
              <Text>Yes, I would like to receive email updates</Text>
            </Flex>
            <Button
              id="send-property-request"
              type="submit"
              className="btn-primary"
              colorScheme="wpHeaderScheme"
              sx={{ width: '100%', borderRadius: 'md' }}
            >
              Send request
            </Button>
          </VStack>
        </form>
      </FormProvider>
    </Box>
  )
}
