import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Box, Button, Collapse, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'

const Mobile = ({
  title,
  card = false,
  children,
  defaultOpen = false,
  reviewsRef,
  ...props
}) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: defaultOpen })
  const padding = card ? 6 : [6, 0]

  return title === 'Reviews' ? (
    <Box
      borderWidth={card ? [0, 1] : 0}
      rounded={['none', 'base']}
      shadow={card ? 'sm' : 'none'}
      bgColor="white"
      ref={reviewsRef}
      {...props}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          title,
          isOpen,
          onToggle,
          padding,
          card
        })
      )}
    </Box>
  ) : (
    <Box
      borderWidth={card ? [0, 1] : 0}
      rounded={['none', 'base']}
      shadow={card ? 'sm' : 'none'}
      bgColor="white"
      {...props}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          title,
          isOpen,
          onToggle,
          padding,
          card
        })
      )}
    </Box>
  )
}

const MobileHeader = ({
  isOpen,
  onToggle,
  reviewsIsOpen,
  reviewsToggle,
  children
}) => {
  const textColor = isOpen ? 'white' : 'wpMain.meta'
  const backgroundColor = isOpen ? 'wpMain.primary' : 'gray.200'
  if (children === 'Reviews') {
    isOpen = reviewsIsOpen
    onToggle = reviewsToggle
  }
  return (
    <Button
      borderRadius={0}
      onClick={onToggle}
      display="flex"
      w="full"
      _hover={{ background: backgroundColor, color: textColor }}
      sx={{ background: backgroundColor, color: textColor }}
    >
      <Text flex="1" textAlign="left" color={textColor}>
        {children}
      </Text>
      {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </Button>
  )
}

const MobileBody = ({ title, isOpen, reviewsIsOpen, children }) => {
  if (title === 'Reviews') {
    isOpen = reviewsIsOpen
  }
  return (
    <Collapse in={isOpen} animateOpacity>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          title,
          isOpen
        })
      )}
    </Collapse>
  )
}

Mobile.Header = MobileHeader
Mobile.Body = MobileBody

export default Mobile
