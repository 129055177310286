import { usePageContext } from '@/contexts/page'
import { useQuoteContext } from '@/contexts/quote'
import { useSettingContext } from '@/contexts/settings'
import useSignalPath from '@/hooks/useSignalPath'
import { Box, Button, Heading } from '@chakra-ui/react'
import { format } from 'date-fns'
import { omitBy } from 'lodash'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo, useState } from 'react'

export const BookNowBtn = ({ isQuote, isLoading }) => {
  const { isQuoteSupported, settings } = usePageContext()
  const { query } = useRouter()
  const { demo } = useSettingContext()
  const { dates, isInvalidCheckDate, customQuoteChanged } = useQuoteContext()
  const [navigating, setNavigating] = useState(false)
  const signalPath = useSignalPath()

  useEffect(() => {
    setNavigating(false)
  }, [])

  const isBookDisabled = settings.bookingsDisabled === 'true'
  const bookDisabledMessage = settings.bookingsDisabledMessage
  const isBookNowBtnDisabled = useMemo(() => {
    if (demo && !isQuoteSupported) return true
    if (isInvalidCheckDate) return true
    if (!isQuote) return true
    if (isBookDisabled) return true
    return false
  }, [isQuoteSupported, isInvalidCheckDate, isQuote, demo])

  const router = useRouter()
  const { t } = useTranslation('property')

  const navigate = () => {
    setNavigating(true)

    const queryParams = omitBy(
      {
        checkin: format(dates.from, 'yyyy-MM-dd'),
        checkout: format(dates.to, 'yyyy-MM-dd'),
        adults: query?.adults,
        children: query?.children,
        pets: query?.pets,
        quote_id: !customQuoteChanged ? query?.quote_id : null
      },
      (v) => !v
    )
    const href = signalPath('reservation', { query: queryParams })
    router.push(href)
  }

  return (
    <Box width="100%" pb={5}>
      <Button
        w="100%"
        id="book-now"
        size="lg"
        borderRadius="lg"
        className="btn-primary"
        colorScheme="wpHeaderScheme"
        loadingText={t('book-now-btn-text')}
        isLoading={isLoading || navigating}
        isDisabled={isBookNowBtnDisabled}
        onClick={navigate}
      >
        {t('book-now-btn-text')}
      </Button>
      {isBookDisabled && bookDisabledMessage && (
        <Heading as="p" size="sm" mb={2} mt={2}>
          {bookDisabledMessage}
        </Heading>
      )}
    </Box>
  )
}
