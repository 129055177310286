import { useSignalContext } from '@/contexts/signal'
import { Box, Flex, Skeleton, Text, VStack } from '@chakra-ui/react'
import { capitalize } from 'lodash'

const LineItemRow = ({
  lineItem,
  currencyFormatter,
  fontSize = 'md',
  fontWeight = 'normal',
  isLoading,
  ...props
}) => {
  const { locale } = useSignalContext()

  return (
    <Box width="100%" {...props}>
      <Skeleton isLoaded={!isLoading}>
        <Flex
          justify="space-between"
          fontSize={fontSize}
          fontWeight={fontWeight}
          mx="-3"
          px="3"
          borderRadius="md"
          bgColor={lineItem.isTravelInsurance ? 'gray.100' : ''}
        >
          <Text as="span">{lineItem?.label ?? capitalize(lineItem?.type)}</Text>
          <Text as="span">{currencyFormatter(lineItem?.value, locale)}</Text>
        </Flex>
      </Skeleton>
    </Box>
  )
}
export const LineItemDetails = ({
  lineItems,
  currencyFormatter,
  isLoading = false,
  fontSize = 'md'
}) => {
  if (!lineItems) return null
  const priceItemTypeTotal = 'total'
  const totalLineItem = lineItems?.find((li) => li.type === priceItemTypeTotal)
  const lineItemsWithoutTotal = lineItems?.filter(
    (li) => li.type !== priceItemTypeTotal
  )
  return (
    <VStack spacing={4} width="100%">
      {lineItemsWithoutTotal?.map((li, key) => (
        <LineItemRow
          key={key}
          lineItem={li}
          isLoading={isLoading}
          currencyFormatter={currencyFormatter}
          fontSize={fontSize}
        />
      ))}
      <LineItemRow
        lineItem={totalLineItem}
        currencyFormatter={currencyFormatter}
        fontSize={fontSize}
        isLoading={isLoading}
        fontWeight="bold"
      />
    </VStack>
  )
}
export const PropertyLineItemDetails = ({
  lineItems,
  currencyFormatter,
  isLoading = false,
  fontSize = 'md'
}) => {
  if (!lineItems) return null
  const priceItemTypeTotal = 'total'
  const totalLineItem = lineItems?.find((li) => li.type === priceItemTypeTotal)
  const lineItemsWithoutTotal = lineItems?.filter(
    (li) => li.type !== priceItemTypeTotal
  )
  return (
    <Box pb={5}>
      <VStack spacing={2} width="100%">
        {lineItemsWithoutTotal?.map((li, key) => (
          <LineItemRow
            key={key}
            lineItem={li}
            isLoading={isLoading}
            currencyFormatter={currencyFormatter}
            fontSize={fontSize}
          />
        ))}
        <LineItemRow
          lineItem={totalLineItem}
          currencyFormatter={currencyFormatter}
          fontWeight="bold"
          fontSize="md"
          sx={{ paddingTop: 1 }}
        />
      </VStack>
    </Box>
  )
}
