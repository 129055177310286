import { useSignalContext } from '@/contexts/signal'
import { formatDate } from '@/lib/localeUtils'
import { Box, Flex, Heading, HStack, Skeleton, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { StarRating } from '../core/StarRating'

/**
 *
 * @param {PropertyReview} review
 * @param {Boolean} isLoading
 * @return {JSX.Element}
 * @constructor
 */
const PropertyReview = ({ review, isLoading = false }) => {
  const { t } = useTranslation('reviews')
  const { locale } = useSignalContext()
  return (
    <Box py={8} className="property-review">
      <Skeleton isLoaded={!isLoading}>
        <Heading as="p" size="md" mb={3} dangerouslySetInnerHTML={{__html: review.title}} />

        <Flex mb={3} flexDir={{ base: 'column', md: 'row' }}>
          <Text
            fontStyle="italic"
            fontSize="md"
            whiteSpace="nowrap"
            mr={10}
            color="gray.600"
          >
            {review.display_name}
          </Text>
          <Text
            fontSize="md"
            as="p"
            whiteSpace="nowrap"
            mr={10}
            color="gray.600"
          >
            {t('stayed-review-date')}{' '}
            {formatDate(
              review.check_in || review.review_date,
              locale,
              'MMM yyyy'
            )}
          </Text>
          <HStack spacing={2} w="100%" whiteSpace="nowrap">
            <Text fontSize="md" verticalAlign="top" color="gray.600">
              {t('rating-label')}
            </Text>
            <StarRating value={parseInt(review.rating)} />
          </HStack>
        </Flex>
        <Text fontSize="md" color="gray.800" dangerouslySetInnerHTML={{__html: review.review}} />
        {review.manager_response && (
          <Box
            _before={{
              borderRadius: '3px',
              bg: 'gray.300',
              content: '""',
              height: '100%',
              left: 3,
              position: 'absolute',
              width: 1
            }}
            mt={6}
            pl={10}
            position="relative"
          >
            <Heading size="md">{t('managers-response')}</Heading>
            <Text dangerouslySetInnerHTML={{__html: review.manager_response}} />
          </Box>
        )}
      </Skeleton>
    </Box>
  )
}

export default PropertyReview
