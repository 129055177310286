import Mobile from '@/components/core/Mobile'
import { usePropertyContext } from '@/contexts/property'
import {
  Box,
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useStyleConfig
} from '@chakra-ui/react'
import React from 'react'

// eslint-disable-next-line
const StyledTab = ({ children }) => {
  return (
    <Tab
      _selected={{
        color: 'wpMain.primary',
        borderColor: 'wpMain.primary',
        backgroundColor: 'wpMain.bg'
      }}
      color="gray.600"
      _hover={{
        backgroundColor: 'wpMain.bg',
        color: 'wpMain.primary',
        borderColor: 'wpMain.primary'
      }}
    >
      {children}
    </Tab>
  )
}

export const PropertyInfo = ({
  property,
  isMobile,
  tabIndex,
  onChange = () => null
}) => {
  const style = useStyleConfig('Box', { variant: 'infoPanel' })
  const { tabs } = usePropertyContext()

  if (isMobile) return <Divider />

  return (
    <Box {...style} className="property-page__tab-panel" scrollMarginTop={32}>
      <Tabs
        index={tabIndex}
        isLazy
        isFitted
        onChange={onChange}
        id="property-tabs"
      >
        <TabList borderColor="gray.200">
          {Object.keys(tabs).map((title, i) => (
            <StyledTab key={i}>{title}</StyledTab>
          ))}
        </TabList>

        <TabPanels>
          {Object.entries(tabs).map(([title, Comp]) => (
            <TabPanel key={title} p="0">
              <Comp property={property} />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export const MobileSection = ({
  property,
  isMobile = false,
  reviewsRef,
  reviewsIsOpen,
  reviewsToggle
}) => {
  const { tabs } = usePropertyContext()
  if (!isMobile) return null
  const tabsDefaultOpen = ['Summary']

  return (
    <Box pb={5} className="property-page__mobile-panel">
      {Object.entries(tabs).map(([title, Comp], i) => (
        <Mobile
          key={i}
          title={title}
          defaultOpen={tabsDefaultOpen.includes(title)}
          reviewsRef={reviewsRef}
        >
          <Mobile.Header
            reviewsRef={reviewsRef}
            reviewsIsOpen={reviewsIsOpen}
            reviewsToggle={reviewsToggle}
          >
            {title}
          </Mobile.Header>
          <Mobile.Body reviewsIsOpen={reviewsIsOpen}>
            <Comp property={property} isMobile={isMobile} />
          </Mobile.Body>
        </Mobile>
      ))}
    </Box>
  )
}
