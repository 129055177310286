import { Map } from '@/components/core/Map'
import { usePropertyContext } from '@/contexts/property'
import { useSettingContext } from '@/contexts/settings'
import { Box, Heading, Text, useStyleConfig } from '@chakra-ui/react'
import React, { useMemo } from 'react'

export const PropertyMap = ({ property }) => {
  const { latLng } = usePropertyContext()
  const { settings } = useSettingContext()
  const style = useStyleConfig('Box', { variant: 'mobileProperty' })

  const center = {
    lat: latLng.lat,
    lng: latLng.lng
  }

  const address = useMemo(() => {
    const { city, state, country } = property
    return [city, state, country].filter((item) => !!item).join(', ')
  }, [property])

  //

  return (
    <Box
      sx={style}
      className="property-map"
    >
      <Heading as="p" size="md" mb={5}>
        Map
      </Heading>
      <Text mt="5" mb="8" color="gray.800">
        {address}
      </Text>

      <Map
        border="1px solid"
        borderColor="gray.300"
        borderRadius="5px"
        padding="10px"
        bounds={{ center }}
        zoomSetting={settings?.mapZoom?.property}
        mapType={settings?.propertyMapView}
      >
        <Map.PinMarker name={property.name} lat={latLng.lat} lng={latLng.lng} />
      </Map>
    </Box>
  )
}
