import { useQuoteContext } from '@/contexts/quote'
import gift from '@/icons/gift.svg'
import WarningIcon from '@/icons/warning.svg'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  SkeletonCircle,
  SkeletonText,
  Text,
  useToken,
  VStack
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

const CustomQuoteDetails = () => {
  const { t } = useTranslation('custom-quote')
  const {
    customQuoteChanged,
    revertCustomQuoteDates,
    data: {
      isLoading,
      quote: { saved_quote }
    }
  } = useQuoteContext()
  const [primaryColor] = useToken('colors.wpMain', ['primary'])

  if (isLoading)
    return (
      <Box padding="6" bg="white" justifyContent="center" alignItems="center">
        <SkeletonCircle size="10" />
        <SkeletonText mt="4" noOfLines={1} spacing="4" skeletonHeight="2" />
        <SkeletonText mt="4" noOfLines={2} spacing="4" skeletonHeight="2" />
      </Box>
    )

  return (
    <>
      <VStack
        py={6}
        px={5}
        mx={-5}
        alignItems="center"
        borderBottom="2px solid"
        borderColor="gray.200"
      >
        {!customQuoteChanged ? (
          <>
            <Icon as={gift} color={primaryColor} boxSize={8} />

            <Heading as="h5" fontSize="2xl">
              {t('special-offer')}
            </Heading>
          </>
        ) : (
          <Box
            bgColor={'warning.100'}
            py="20px"
            px="16px"
            display={'flex'}
            alignContent="flex-start"
            gap={'12px'}
            borderRadius="5px"
            w="full"
          >
            <Icon as={WarningIcon} fill="warning.200" />
            <Flex flexDir={'column'}>
              <Text fontSize={'14px'} color="gray.800">
                {t('changed-booking-dates')}
              </Text>
              <Text fontSize={'14px'} color="gray.800" wordBreak={'break-word'}>
                {t('not-eligible-for-our-special-offer')}
              </Text>
              <Button
                variant="ghost"
                _hover={{ bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
                color="warning.200"
                fontSize={'14px'}
                padding="0"
                onClick={revertCustomQuoteDates}
              >
                {t('go-back-to-special-offer')}
              </Button>
            </Flex>
          </Box>
        )}
      </VStack>
      {!customQuoteChanged && (
        <VStack
          py={6}
          borderBottom="2px solid"
          borderColor="gray.200"
          fontSize="sm"
        >
          {saved_quote && saved_quote.confirmation_id && (
            <HStack justifyContent="space-between" w="100%">
              <Text>{t('quote-confirmation')}</Text>
              <Text>{saved_quote.confirmation_id}</Text>
            </HStack>
          )}
          {saved_quote && saved_quote.first_name && saved_quote.last_name && (
            <HStack justifyContent="space-between" w="100%">
              <Text>{t('name')}</Text>
              <Text>{`${saved_quote.first_name} ${saved_quote.last_name}`}</Text>
            </HStack>
          )}
        </VStack>
      )}
    </>
  )
}

export default CustomQuoteDetails
