import { CustomCalendarCaptionLabel } from '@/components/core/CustomCalendarCaptionLabel'
import FormControlWithError from '@/components/core/form/FormControlWithError'
import { useSignalContext } from '@/contexts/signal'
import { getLocaleStartOfWeek, localeFromISODate } from '@/lib/localeUtils'
import {
  Box,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react'
import { format } from 'date-fns'
import CalendarIcon from 'icons/calendar.svg'
import React, { useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { useFormContext } from 'react-hook-form'

const DateInputWithPopoverCalendar = ({
  placeholderText = '',
  name = 'dateInputWithPopoverCalendar',
  validateInput
}) => {
  const [selected, setSelected] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  const { getValues, setValue, register, formState } = useFormContext()
  const { locale } = useSignalContext()
  const startDayOfWeek = getLocaleStartOfWeek(locale)

  const handleDaySelect = (date) => {
    setSelected(date)
    if (date) {
      setValue(name, localeFromISODate(format(date, 'yyyy-MM-dd'), locale), {
        shouldDirty: true
      })
      onClose()
    } else {
      setValue(name, '')
    }
  }

  return (
    <Box w="100%">
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        matchWidth={true}
        trigger="click"
        variant="responsive"
      >
        <>
          <PopoverTrigger>
            <HStack align="flex-start">
              <FormControlWithError name={name} errors={formState.errors}>
                <InputGroup cursor="pointer">
                  <Input
                    id={name}
                    placeholder={placeholderText}
                    cursor="pointer"
                    value={getValues(name)}
                    onInput={(e) => e.preventDefault()}
                    onKeyUp={(e) => e.preventDefault()}
                    onKeyDown={(e) => {
                      e.preventDefault()
                      if (e.key === 'Enter') {
                        onOpen()
                      }
                    }}
                    onPaste={(e) => e.preventDefault()}
                    {...register(name, {
                      required: true,
                      validate: validateInput
                    })}
                  />
                  <InputRightElement>
                    <Icon
                      aria-label="Open calendar"
                      as={CalendarIcon}
                      fill="gray.600"
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControlWithError>
            </HStack>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody m={0} p={0}>
              <DayPicker
                components={{
                  CaptionLabel: CustomCalendarCaptionLabel
                }}
                initialFocus={isOpen}
                mode="single"
                defaultMonth={selected}
                selected={selected}
                onSelect={handleDaySelect}
                toDate={new Date()}
                weekStartsOn={startDayOfWeek}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      </Popover>
    </Box>
  )
}

export default DateInputWithPopoverCalendar
