import { useSignalContext } from '@/contexts/signal'
import {
  Box,
  Button,
  Center,
  HStack,
  Link,
  SimpleGrid,
  Text
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IoHeart, IoHeartOutline } from 'react-icons/io5'

const BaseIcon = ({ text, icon, href = null }) => {
  const inner = href ? (
    <Link variant="wpColor" href={href}>
      {text}
    </Link>
  ) : (
    <Text>{text}</Text>
  )

  return (
    <Center>
      <HStack spacing={3}>
        {icon}
        {inner}
      </HStack>
    </Center>
  )
}

const FooterIcon = ({ text, icon, onClick = null, href = null }) => {
  if (onClick)
    return (
      <Button onClick={onClick} className="no-background">
        <BaseIcon text={text} icon={icon} />
      </Button>
    )

  return <BaseIcon text={text} icon={icon} href={href} />
}

export const SidebarFooter = ({ property }) => {
  const [favoriteIds, setFavoriteIds] = useState()
  const { favoritesProperties, setFavoritesProperties } = useSignalContext()

  useEffect(() => {
    if (!favoriteIds || !favoritesProperties) {
      setFavoriteIds(JSON.parse(localStorage.getItem('favoriteIds') || '[]'))
    }
    localStorage.setItem('favoriteIds', JSON.stringify(favoriteIds))
  }, [favoriteIds, favoritesProperties])

  const toggleFavorites = () => {
    const { name, slug, beds, baths, occu, images, id } = property
    const firstImage = images[0].full_url

    if (favoriteIds.includes(id)) {
      const newFavoriteIds = favoriteIds.filter((favorite) => favorite !== id)
      const newFavoritesProperties = favoritesProperties.filter(
        (property) => property.id !== id
      )
      setFavoriteIds(newFavoriteIds)
      setFavoritesProperties(newFavoritesProperties)
    } else {
      setFavoriteIds([...favoriteIds, id])
      setFavoritesProperties([
        ...favoritesProperties,
        {
          id,
          name,
          slug,
          beds,
          baths,
          occu,
          firstImage
        }
      ])
    }
  }

  return (
    <Box className="pbs-footer">
      <SimpleGrid columns={1} spacing={3} my={3}>
        {(favoriteIds && favoriteIds.includes(property.id) && (
          <FooterIcon
            text="Favorite"
            icon={<IoHeart w={6} h={6} color="red" />}
            onClick={toggleFavorites}
          />
        )) || (
          <FooterIcon
            text="Favorite"
            icon={<IoHeartOutline w={6} h={6} color="gray.500" />}
            onClick={toggleFavorites}
          />
        )}
      </SimpleGrid>
    </Box>
  )
}
