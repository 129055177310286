import { CustomCalendarCaptionLabel } from '@/components/core/CustomCalendarCaptionLabel'
import IconTriggerButton from '@/components/core/IconTriggerButton'
import { useQuoteContext } from '@/contexts/quote'
import { useSettingContext } from '@/contexts/settings'
import { useSignalContext } from '@/contexts/signal'
import { getLocaleStartOfWeek, localeFromISODate } from '@/lib/localeUtils'
import { selectDateRange } from '@/lib/utils'
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@chakra-ui/react'
import { add, format } from 'date-fns'
import CalendarIcon from 'icons/calendar.svg'
import React, { useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'

export const SelectDates = ({
  isMobile = false,
  onQuoteFetched,
  disabled = false
}) => {
  const { locale } = useSignalContext()
  const { settings } = useSettingContext()

  const {
    dates,
    onDateChange,
    resetDates,
    fetchQuote,
    checkInDays,
    checkOutDays,
    disabledDays
  } = useQuoteContext()
  const [month, setMonth] = useState(dates.from)

  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  const popoverStyling = isMobile ? {} : { minWidth: 625 }

  useEffect(() => {
    if (!isOpen) setMonth(dates.from)
  }, [dates, isOpen])

  const onSelect = (range, selectedDay) => {
    const newDates = onDateChange(
      selectDateRange(dates, selectedDay),
      selectedDay
    )
    const hasKeys = !!newDates.from && !!newDates?.to
    if (hasKeys && newDates.from !== newDates.to) {
      onClose()
      fetchQuote(newDates.from, newDates.to).then((success) =>
        onQuoteFetched(success)
      )
    }
  }

  const startDayOfWeek = getLocaleStartOfWeek(locale)

  const getDateContent = (date, locale) => {
    return date
      ? localeFromISODate(format(new Date(date), 'yyyy-MM-dd'), locale)
      : ''
  }

  const fieldCommonProps = (input) => {
    return {
      borderColor: 'wpMain.border',
      content: getDateContent(input, locale),
      contentSize: 'md',
      colors: {
        icon: 'gray.500',
        title: 'gray.800',
        content: 'gray.800'
      },
      LeftIcon: CalendarIcon,
      px: '4',
      py: !input ? 2.5 : 0,
      titleSize: 'xs',
      flex: 1,
      justifyContent: 'flex-start'
    }
  }

  return (
    <Box>
      <Popover
        onOpen={onOpen}
        onClose={onClose}
        isOpen={!disabled && isOpen}
        placement="bottom"
        variant="responsive"
        trigger="click"
        matchWidth={true}
      >
        <PopoverTrigger>
          <ButtonGroup
            className="select-dates"
            w="full"
            height={'50px'}
            variant="outline"
            isAttached
          >
            <IconTriggerButton
              className="date-picker-left"
              borderRight="none"
              disabled={disabled}
              title={'Check-in date'}
              {...fieldCommonProps(dates.from)}
            />
            <IconTriggerButton
              className="date-picker-right"
              disabled={disabled}
              title={'Check-out date'}
              {...fieldCommonProps(dates.to)}
            />
          </ButtonGroup>
        </PopoverTrigger>
        <PopoverContent sx={popoverStyling}>
          <DayPicker
            mode="range"
            modifiers={{ checkin: checkInDays, checkout: checkOutDays }}
            modifiersClassNames={{
              checkin: 'checkin-day',
              checkout: 'checkout-day'
            }}
            components={{
              CaptionLabel: CustomCalendarCaptionLabel
            }}
            weekStartsOn={startDayOfWeek}
            selected={dates}
            fromDate={add(new Date(), { days: +settings.earliestCheckin })}
            toDate={add(new Date(), {
              months: +settings.popupCalendarMaxMonths
            })}
            onSelect={onSelect}
            disabled={disabledDays}
            month={month}
            onMonthChange={setMonth}
            numberOfMonths={isMobile ? 1 : 2}
          />
        </PopoverContent>
      </Popover>
      <Flex direction="row-reverse" py={3}>
        <Button
          size="xs"
          fontWeight="normal"
          variant="ghost"
          disabled={disabled}
          onClick={resetDates}
        >
          Clear Dates
        </Button>
      </Flex>
    </Box>
  )
}
