import { FormControl, FormErrorMessage } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import React from 'react'

// access object with dot notation
function get(obj, path) {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

const getErrorMessage = (error) => {
  if (error.message) {
    return error.message
  }
  if (error.type === 'required') {
    return 'This field is required.'
  }
  if (error.type === 'pattern') {
    if (error.ref.name.toLowerCase().includes('email')) {
      return 'Please enter a valid email.'
    }
    if (error.ref.name.toLowerCase().includes('cvv')) {
      return 'Please enter a valid security code.'
    }
  }
  return 'Please fix any errors'
}

const FormControlWithError = ({ name, errors, children }) => {
  const error = get(errors, name)
  return (
    <FormControl isInvalid={error}>
      {React.cloneElement(children, { name })}
      <FormErrorMessage id={`${name}-error`}>
        {error && getErrorMessage(error)}
      </FormErrorMessage>
    </FormControl>
  )
}

FormControlWithError.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired
}

export default FormControlWithError
