import { AvailabilityCalendar } from '@/components/property/AvailabilityCalendar'
import { BookingSideBar } from '@/components/property/BookingSideBar'
import { PopupImageGallery } from '@/components/property/PopupImageGallery'
import { PropertyImageGallery } from '@/components/property/PropertyImageGallery'
import { PropertyRequest } from '@/components/property/PropertyRequest'
import { PropertyReviews } from '@/components/property/PropertyReviews'
import { PropertySpecials } from '@/components/property/PropertySpecials'
import { PropertySummary } from '@/components/property/PropertySummary'
import { usePageContext } from '@/contexts/page'
import PropertyContextProvider from '@/contexts/property'
import QuoteContextProvider from '@/contexts/quote'
import SettingContextProvider from '@/contexts/settings'
import { checkInCheckoutIsValidToDate, getCDNImageUrl } from '@/lib/utils'
import {
  Box,
  Container,
  Divider,
  Flex,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useMemo, useRef, useState } from 'react'
import { useEffectOnce } from 'react-use'
import BannerPayment from '../core/BannerPayment'
import Breadcrumbs from '../core/Breadcrumbs'
import { PageSplash } from '../property/PageSplash'
import { MobileSection, PropertyInfo } from '../property/PropertyInfo'
import { PropertyMap } from '../property/PropertyMap'

/**
 *
 * @param {DetailedProperty | null} property
 * @param {SignalSettings} settings
 * @param {string} engineId
 * @param {string | null} error
 * @param {SignalSeo} seo
 * @return {JSX.Element}
 * @constructor
 */
export const Property = ({
  property,
  settings,
  engineId,
  paymentConfiguration
}) => {
  const { seo, longTermSettings } = usePageContext()
  const { query } = useRouter()
  const [selectedImage, setSelectedImage] = React.useState(0)
  const [showPopupGallery, setShowPopupGallery] = React.useState(false)
  const { checkinDate, checkoutDate } = React.useMemo(() => {
    return checkInCheckoutIsValidToDate(
      query.checkin ?? null,
      query.checkout ?? null
    )
  }, [query.checkin, query.checkout])

  const tabs = useMemo(() => {
    const tabs = {}
    const propertyTabs = [
      { key: 'Summary', comp: PropertySummary },
      { key: 'Calendar', comp: AvailabilityCalendar },
      { key: 'Map', comp: PropertyMap },
      { key: 'Reviews', comp: PropertyReviews },
      { key: 'Request', comp: PropertyRequest }
    ]
    if (property.specials?.length) {
      propertyTabs.push({ key: 'Specials', comp: PropertySpecials })
    }

    propertyTabs.forEach((tabItem) => {
      tabs[tabItem.key] = tabItem.comp
    })

    return tabs
  }, [property.specials])

  const reviewsRef = useRef(null)
  // Need to access from both OverallReview and Mobile components
  const { isOpen, onToggle } = useDisclosure()

  const queryInTabs =
    query &&
    Object.keys(tabs).find((tabName) =>
      Object.keys(query).some((q) => q === tabName.toLowerCase())
    )
  const isWriteReview = query['write-review'] || null
  const initialTabIndex = queryInTabs
    ? Object.keys(tabs).findIndex((tabName) =>
      Object.keys(query).some((q) => q === tabName.toLowerCase())
    )
    : 0
  const [tabIndex, setTabIndex] = useState()
  useEffectOnce(() => {
    if (queryInTabs) {
      setTabIndex(initialTabIndex)
    }
  })
  let latLng = { lat: 0, lng: 0 }
  if (!!property)
    latLng = {
      lat: property.property_info.latitude,
      lng: property.property_info.longitude
    }
  const isMobile = useBreakpointValue({ base: false, sm: true, md: false })

  let firstImageUrl = ''
  if (property.images[0] && property.images[0].full_url)
    firstImageUrl = getCDNImageUrl(
      settings.uploadImageSize,
      property.images[0].full_url,
      'large'
    )

  // build structured data
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'VacationRental',
    "additionalType": property.prop_type,
    "brand": {
      "@type": "Brand",
      "name": settings.companyName
    },
    "containsPlace": {
      "@type": "Accommodation",
      "additionalType": "EntirePlace",
      "occupancy": {
        "@type": "QuantitativeValue",
        "value": property.occu,
      },
      "numberOfBathroomsTotal": property.baths,
      "numberOfBedrooms": property.beds,
    },
    "identifier": property.property_info.prop_id,
    "latitude": property.property_info.latitude,
    "longitude": property.property_info.longitude,
    "name": property.prop_name,
    "aggregateRating": {
      "@type": "AggregateRating",
      "reviewCount": property.reviews_summary?.count ?? 0,
      "bestRating": 5,
      "ratingValue": property.reviews_summary?.rating ?? 0
    },
    "image": property.images.map((image) => image.full_url),
  }

  return (
    <SettingContextProvider settings={settings}>
      <PropertyContextProvider
        property={property}
        seo={seo}
        latLng={latLng}
        engineId={engineId}
        tabs={tabs}
        queryInTabs={queryInTabs}
        isWriteReview={isWriteReview}
      >
        <QuoteContextProvider
          engineId={engineId}
          propertyId={property.id}
          rawUnavailableDates={property.unavailable_dates}
          settings={settings}
          startDate={checkinDate}
          endDate={checkoutDate}
          longTermSettings={longTermSettings}
        >
          <Head>
            <title>{seo.page_title}</title>
            <meta name="description" content={seo.page_description} />
            <meta name="keywords" content={seo.page_keywords} />
            <meta property="og:image" content={firstImageUrl} />
            <script
              key="structured-data"
              type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
            />
          </Head>

          <Box>
            <BannerPayment paymentConfiguration={paymentConfiguration} />
            <Breadcrumbs />
            {showPopupGallery && (
              <PopupImageGallery
                selectedImage={selectedImage}
                property={property}
                settings={settings}
                onChange={(id) => setSelectedImage(id)}
                onClose={() => setShowPopupGallery(false)}
              />
            )}
            <PropertyImageGallery
              selectedImage={selectedImage}
              property={property}
              settings={settings}
              onClick={(id) => {
                setSelectedImage(id)
                setShowPopupGallery(true)
              }}
            />
            <Container
              maxW="container.2xl"
              px={5}
              pb={{ base: 5, md: 0 }}
              className="property-page__wrapper"
              mb={20}
            >
              <PageSplash property={property} settings={settings} />
              {isMobile && <Divider />}
              <Flex direction={{ base: 'column-reverse', md: 'row' }}>
                <PropertyInfo
                  property={property}
                  settings={settings}
                  latLng={latLng}
                  isMobile={isMobile}
                  tabIndex={tabIndex}
                  onChange={(index) => setTabIndex(index)}
                />

                <BookingSideBar
                  property={property}
                  isMobile={isMobile}
                  setTabIndex={setTabIndex}
                  reviewsRef={reviewsRef}
                  reviewsIsOpen={isOpen}
                  reviewsToggle={onToggle}
                />
              </Flex>
            </Container>
            <MobileSection
              property={property}
              isMobile={isMobile}
              reviewsRef={reviewsRef}
              reviewsIsOpen={isOpen}
              reviewsToggle={onToggle}
            />
          </Box>
        </QuoteContextProvider>
      </PropertyContextProvider>
    </SettingContextProvider>
  )
}
