/**
 *
 * @param {string} date
 * @param {number | null} rate
 * @param {function} formatter
 * @constructor
 */
import { Box, Text } from '@chakra-ui/react'

export const Day = (date, rate, formatter, showRates = false) => {
  if (!showRates) return <span>{date}</span>
  return (
    <Box as="span" whiteSpace="pre" height="100%">
      <Text as="p" fontSize="sm">
        {date}
      </Text>
      <Text as="p" fontSize="10px" color="gray.600">
        {rate ? formatter(rate) : ' '}
      </Text>
    </Box>
  )
}
