import { PropertyLineItemDetails } from '@/components/core/LineItemDetails'
import PaymentSchedule from '@/components/core/PaymentSchedule'
import { useSignalContext } from '@/contexts/signal'
import { currencyFormatter } from '@/lib/currency'
import { localeFromISODate } from '@/lib/localeUtils'
import { Box, Divider, Heading, Stack, Text } from '@chakra-ui/react'
import React from 'react'

export const PropertyBookingQuote = ({ quote }) => {
  const { locale } = useSignalContext()

  if (!Object.keys(quote).length) return null
  const formatCurrency = currencyFormatter(quote?.currency, locale)

  return (
    <Stack py={5}>
      <Box mb={3}>
        <Heading as="p" size="md" mb={2}>
          Booking Details
        </Heading>
        <Text
          as="u"
          fontSize="md"
          mb={3}
          color="wpMain.primary"
          data-testid="booking-dates"
        >
          {localeFromISODate(quote.checkin, locale)} -{' '}
          {localeFromISODate(quote.checkout, locale)}
        </Text>
      </Box>
      <PropertyLineItemDetails
        currencyFormatter={formatCurrency}
        lineItems={quote.line_items}
        sx={{ marginBottom: 4 }}
      />
      <Divider />
      <PaymentSchedule
        title="Payment Schedule"
        currency={quote?.currency}
        payments={quote?.payments_schedule}
        pt={2}
      />
    </Stack>
  )
}
