import { Day } from '@/components/property/calendar/Day'
import PAGE_NAMES from '@/constants/page-names'
import { usePageContext } from '@/contexts/page'
import { useQuoteContext } from '@/contexts/quote'
import { useSettingContext } from '@/contexts/settings'
import { useSignalContext } from '@/contexts/signal'
import { getApi } from '@/lib/client/api'
import { captureSignalQuote } from '@/lib/client/posthog'
import { currencyFormatter } from '@/lib/currency'
import { getLocaleStartOfWeek } from '@/lib/localeUtils'
import { getStartOfMonth, isStartOfMonth, selectDateRange } from '@/lib/utils'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
  useStyleConfig,
  useToken,
  VStack
} from '@chakra-ui/react'
import { add, format } from 'date-fns'
import React, { useCallback } from 'react'
import { DayPicker } from 'react-day-picker'
import useSWR from 'swr'

const api = getApi()

/**
 *
 * @param {Date} startDate
 * @param {DateRange} selected
 * @param {Date[]} disabledDates
 * @param {function} onSelect
 * @param {function} formatDay
 * @param {string} locale
 * @param {number} popupCalendarMaxMonths
 * @return {JSX.Element[]}
 * @constructor
 */
const InternalGrid = ({
  startDate,
  selected,
  onSelect,
  formatDay,
  locale,
  popupCalendarMaxMonths,
}) => {
  const { checkInDays, checkOutDays, disabledDays } = useQuoteContext()
  const calendarMonthsCount = isStartOfMonth()
    ? popupCalendarMaxMonths
    : popupCalendarMaxMonths + 1
  const startOfMonth = getStartOfMonth()
  const startDayOfWeek = getLocaleStartOfWeek(locale)
  const endDateUsingMaxMonthsSetting = add(new Date(), {
    months: popupCalendarMaxMonths
  })

  return Array(calendarMonthsCount)
    .fill(0)
    .map((_, i) => {
      const currentDate = startOfMonth.add(i, 'month')
      const isLastMonthInCalendar = i + 1 === calendarMonthsCount
      const isFirstMonthInCalendar = i === 0
      const fromDate = isFirstMonthInCalendar ? startDate : null
      const toDate = isLastMonthInCalendar ? endDateUsingMaxMonthsSetting : null

      return (
        <GridItem
          key={i}
          className="grid-item"
          sx={{
            border: '1px',
            borderColor: 'gray.200',
            borderRadius: 5,
            paddingX: 2
          }}
        >
          <VStack spacing={4}>
            <Text sx={{ fontWeight: 'bold', paddingTop: 4 }} as="span">
              {currentDate.format('MMMM YYYY')}
            </Text>
            <DayPicker
              modifiers={{ checkin: checkInDays, checkout: checkOutDays }}
              modifiersClassNames={{
                checkin: 'checkin-day',
                checkout: 'checkout-day'
              }}
              mode="range"
              weekStartsOn={startDayOfWeek}
              selected={selected}
              fromDate={fromDate}
              toDate={toDate}
              disabled={disabledDays}
              onSelect={onSelect}
              defaultMonth={currentDate.toDate()}
              disableNavigation
              formatters={{ formatCaption: () => null, formatDay }}
              style={{ paddingBottom: 10 }}
            />
          </VStack>
          {/*</Center>*/}
        </GridItem>
      )
    })
}

const LegendIcon = ({ color, text }) => {
  return (
    <HStack spacing={1}>
      <Box
        bg={color}
        sx={{
          padding: 2,
          borderRadius: 'sm',
          border: '1px',
          borderColor: 'gray.300'
        }}
      />
      <Text fontSize="sm">{text}</Text>
    </HStack>
  )
}

const AvailabilityHeader = () => {
  const [primaryColor] = useToken('colors.wpMain', ['primary'])
  return (
    <Flex
      justifyContent="flex-end"
      direction="row"
      sx={{ alignItems: 'center', width: '100%', paddingBottom: 10 }}
    >
      <HStack spacing={6}>
        <LegendIcon color="white" text="Available" />
        <LegendIcon color="#dddddd" text="Other reservations" />
        <LegendIcon color={primaryColor} text="Selected" />
      </HStack>
    </Flex>
  )
}

export const AvailabilityCalendar = ({ property, isOpen = true }) => {
  const { locale, token } = useSignalContext()
  const { settings } = useSettingContext()
  const { isQuoteSupported } = usePageContext()
  const { dates, onDateChange, fetchQuote, checkInDays, checkOutDays, disabledDays } = useQuoteContext()
  const quotingEnabled = !settings.demo || (settings.demo && isQuoteSupported)

  const startDate = new Date()
  const templateColumns = {
    base: 'repeat(1, 1fr)',
    lg: 'repeat(2, 1fr)',
    xl: 'repeat(3, 1fr)'
  }
  const style = useStyleConfig('Box', { variant: 'mobileProperty' })
  const onSelect = useCallback(
    (range, selectedDay) => {
      const newDates = onDateChange(
        selectDateRange(dates, selectedDay),
        selectedDay
      )
      const hasKeys = !!newDates.from && !!newDates?.to
      if (hasKeys && newDates.from !== newDates.to) {
        fetchQuote(newDates.from, newDates.to).then((success) =>
          captureSignalQuote(PAGE_NAMES.PROPERTIES, success)
        )
      }
    },
    [dates, fetchQuote, onDateChange]
  )
  const showRates = settings.propertyCalendar.showRates

  const getDailyRates = async () => {
    if (!showRates) {
      return new Promise(() => { })
    }

    return await api.getPropertyRates(token, property.id)
  }

  const { data } = useSWR(`${property.id}/daily-rates`, getDailyRates)
  const formatCurrency = currencyFormatter(data?.currency, locale, 0)

  const formatDay = useCallback(
    (day, { locale }) => {
      const rates = data?.rates ?? {}
      const isoDate = format(day, 'yyyy-MM-dd')
      const isCheckinDay = checkInDays.some(
        (day) => format(day, 'yyyy-MM-dd') === isoDate
      )
      const rate = !isCheckinDay ? rates[isoDate] : ''
      return Day(format(day, 'd', locale), rate, formatCurrency, showRates)
    },
    [data, formatCurrency, showRates]
  )

  if (!isOpen) return null
  return (
    <Box sx={style}>
      <VStack className="availability-calendar">
        <AvailabilityHeader />
        <Grid templateColumns={templateColumns} gap={4} w="100%">
          <InternalGrid
            checkInDays={checkInDays}
            checkOutDays={checkOutDays}
            locale={locale}
            selected={dates}
            disabledDates={disabledDays}
            startDate={startDate}
            onSelect={!quotingEnabled ? null : onSelect}
            formatDay={formatDay}
            popupCalendarMaxMonths={+settings.popupCalendarMaxMonths}
          />
        </Grid>
      </VStack>
    </Box>
  )
}
