import { BookNowBtn } from '@/components/property/property-booking-sidebar/BookNowBtn'
import { PropertyBookingQuote } from '@/components/property/property-booking-sidebar/PropertyBookingQuote'
import { SelectDates } from '@/components/property/property-booking-sidebar/SelectDates'
import { SideBarHeader } from '@/components/property/property-booking-sidebar/SideBarHeader'
import { SidebarFooter } from '@/components/property/property-booking-sidebar/SidebarFooter'
import { ShareQuoteModal } from '@/components/property/ShareQuoteModal'
import { UrgencyPrompt } from '@/components/property/UrgencyPrompt'
import PAGE_NAMES from '@/constants/page-names'
import { usePageContext } from '@/contexts/page'
import { useQuoteContext } from '@/contexts/quote'
import { useSettingContext } from '@/contexts/settings'
import { useSignalContext } from '@/contexts/signal'
import { captureSignalQuote } from '@/lib/client/posthog'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Divider,
  useBreakpointValue,
  useDisclosure,
  useStyleConfig,
  VStack
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import DemoCta from '../core/DemoCta'
import CustomQuoteBackBtn from './CustomQuoteBackBtn'
import CustomQuoteDetails from './CustomQuoteDetails'

export const BookingSideBar = ({
  property,
  isMobile = false,
  setTabIndex = () => null,
  reviewsRef,
  reviewsIsOpen,
  reviewsToggle
}) => {
  const { data, dates, isCustomQuote } = useQuoteContext()
  const { settings } = useSettingContext()
  const checkinDate = dates.from
  const checkoutDate = dates?.to
  const boxStyle = useStyleConfig('Box', { variant: 'card' })
  const modalContainerRef = React.useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isQuote = !!Object.keys(data.quote).length
  const boxStyling = isMobile ? {} : boxStyle
  const style = useStyleConfig('Box', { variant: 'sideBar' })
  const maxWidth = useBreakpointValue({ base: {}, md: { maxWidth: 350 } })
  const { isQuoteSupported } = usePageContext()
  const quotingEnabled = !settings.demo || (settings.demo && isQuoteSupported)
  const { t } = useTranslation('property')
  const { standalone } = useSignalContext()

  return (
    <Box
      sx={{ ...style, ...maxWidth }}
      className="property-page__booking-sidebar"
    >
      {standalone && !quotingEnabled && (
        <DemoCta
          isQuoteSupported={isQuoteSupported}
          clientExpressedInterest={settings.isCustomerInterested}
          ctaLine1={t('demo-cta.line1')}
          ctaLine2={t('demo-cta.line2')}
          direction="column"
        />
      )}
      {isCustomQuote && <CustomQuoteBackBtn />}
      <Box
        sx={boxStyling}
        px={{ base: 0, md: 5 }}
        className={isMobile ? '' : 'border-card'}
      >
        <VStack spacing={1} align="stretch">
          {isCustomQuote && <CustomQuoteDetails />}
          <SideBarHeader
            property={property}
            isQuote={isQuote}
            isMobile={isMobile}
            setTabIndex={setTabIndex}
            reviewsRef={reviewsRef}
            reviewsIsOpen={reviewsIsOpen}
            reviewsToggle={reviewsToggle}
          />
          <SelectDates
            isMobile={isMobile}
            disabled={!quotingEnabled}
            onQuoteFetched={(success) =>
              captureSignalQuote(PAGE_NAMES.PROPERTIES, success)
            }
          />
          {data.error && (
            <Box pb={15}>
              <Alert status="error" variant="subtle" alignItems="top">
                <AlertIcon />
                <AlertDescription>{data.error}</AlertDescription>
              </Alert>
            </Box>
          )}
          <BookNowBtn isQuote={isQuote} isLoading={data.isLoading} />
          <Divider />
          <PropertyBookingQuote
            quote={data.quote}
            checkinDate={checkinDate}
            checkoutDate={checkoutDate}
          />
        </VStack>
        <SidebarFooter
            property={property}
        />
        <ShareQuoteModal
          parentContainerRef={modalContainerRef}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Box>
      <UrgencyPrompt
        mt={6}
        sx={boxStyling}
        px={{ base: 0, md: 5 }}
        py={{ base: 3, md: 5 }}
        className={isMobile ? '' : 'border-card'}
      />
    </Box>
  )
}
