import { Amenities } from '@/components/property/Amenities'
import { PropertyDescription } from '@/components/property/PropertyDescription'
import { getPropertyDescription } from '@/components/property/utils'
import { Box, Divider, useStyleConfig, VStack } from '@chakra-ui/react'
import React from 'react'

/**
 *
 * @param {DetailedProperty} property
 * @param {boolean} isOpen
 * @returns {JSX.Element}
 * @constructor
 */
export const PropertySummary = ({ property, isOpen = true }) => {
  const description = getPropertyDescription(property)

  const style = useStyleConfig('Box', { variant: 'mobileProperty' })
  if (!isOpen) return null
  const amenities = property.amenities
  if (!amenities.length && !description) return null
  return (
    <Box sx={{ ...style, width: 'full' }}>
      <VStack divider={<Divider />} spacing={10} align="stretch">
        {!!amenities.length && <Amenities amenities={amenities} />}
        {!!description && <PropertyDescription description={description} />}
      </VStack>
    </Box>
  )
}
