import { StarRating } from '@/components/core/StarRating'
import { usePropertyContext } from '@/contexts/property'
import { HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { scrollIntoView } from 'seamless-scroll-polyfill'

export const OverallReview = ({
  property,
  isMobile,
  setTabIndex = () => null,
  reviewsRef,
  reviewsIsOpen,
  reviewsToggle
}) => {
  const { tabs } = usePropertyContext()
  const reviewSummary = property.reviews_summary ?? {}
  return (
    <HStack mb={3}>
      <StarRating value={Math.round(reviewSummary.rating)} />
      <Text
        fontSize="sm"
        as="u"
        className="subtitle"
        _hover={{ cursor: 'pointer' }}
        onClick={() => {
          const index = Object.keys(tabs).findIndex(
            (element) => element === 'Reviews'
          )
          setTabIndex(index)
          mobileReviewScrollHandler(
            isMobile,
            reviewsRef,
            reviewsIsOpen,
            reviewsToggle
          )
        }}
      >
        ({reviewSummary.count ?? 0} Reviews)
      </Text>
    </HStack>
  )
}

const mobileReviewScrollHandler = (
  isMobile,
  reviewsRef,
  reviewsIsOpen,
  reviewsToggle
) => {
  if (isMobile && reviewsRef.current) {
    if (!reviewsIsOpen) {
      reviewsToggle()
    }
    setTimeout(() => {
      scrollIntoView(reviewsRef.current, { behavior: 'smooth' })
    }, 100)
  }
}
