import FormControlWithError from '@/components/core/form/FormControlWithError'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

export const ShareQuoteModal = ({ parentContainerRef, isOpen, onClose }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [friendsName, setFriendsName] = useState('')
  const [friendsEmail, setFriendsEmail] = useState('')
  const { t } = useTranslation('error-messages')

  const { register, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      friendsName: '',
      friendsEmail: ''
    }
  })

  const sendRequest = () => {
    console.log('BEGIN: shareQuote request')
    const isFormComplete = name && email && friendsName && friendsEmail
    if (!isFormComplete) {
      setError(t('quote-error.incomplete-form'))
      return
    }

    const request = {
      name,
      email,
      friendsName,
      friendsEmail
    }

    console.log(
      'END: shareQuote request.  Request vals = ' + JSON.stringify(request)
    )
    /**
     * TODO: Implement API integration to submit this request to BE
     */
    onClose()
  }
  const { errors } = formState
  return (
    <Modal
      onClose={onClose}
      size="xl"
      isOpen={isOpen}
      portalProps={{ containerRef: parentContainerRef }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Share this quote</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form className="share-quote-form">
            <HStack>
              <Box flex="1">
                <FormControlWithError name="name" errors={errors}>
                  <Input
                    type="text"
                    {...register('name', {
                      required: 'true'
                    })}
                    placeholder="Name *"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControlWithError>

                <FormControlWithError name="email" errors={errors}>
                  <Input
                    type="text"
                    placeholder="Email *"
                    value={email}
                    {...register('email', {
                      required: true
                    })}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControlWithError>
              </Box>
              <Box flex="1">
                <FormControlWithError name="friendsName" errors={errors}>
                  <Input
                    type="text"
                    {...register('friendsName', {
                      required: true
                    })}
                    placeholder="Friend's Name *"
                    value={friendsName}
                    onChange={(e) => setFriendsName(e.target.value)}
                  />
                </FormControlWithError>

                <FormControlWithError name="friendsEmail" errors={errors}>
                  <Input
                    type="text"
                    {...register('friendsEmail', {
                      required: true
                    })}
                    placeholder="Friend's Email *"
                    value={friendsEmail}
                    onChange={(e) => setFriendsEmail(e.target.value)}
                  />
                </FormControlWithError>
              </Box>
            </HStack>
            {error && (
              <Box>
                <Alert
                  rounded="md"
                  status="error"
                  variant="subtle"
                  borderWidth="1px"
                  borderColor="red.500"
                >
                  <AlertIcon />
                  <AlertTitle mr={2}>Error:</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              </Box>
            )}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={5}>
            Close
          </Button>
          <Button onClick={sendRequest} bg="purple.500" color="white">
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
